// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-appearances-js": () => import("./../src/pages/about/appearances.js" /* webpackChunkName: "component---src-pages-about-appearances-js" */),
  "component---src-pages-about-community-js": () => import("./../src/pages/about/community.js" /* webpackChunkName: "component---src-pages-about-community-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-teens-js": () => import("./../src/pages/about/teens.js" /* webpackChunkName: "component---src-pages-about-teens-js" */),
  "component---src-pages-hope-js": () => import("./../src/pages/hope.js" /* webpackChunkName: "component---src-pages-hope-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mybooks-js": () => import("./../src/pages/mybooks.js" /* webpackChunkName: "component---src-pages-mybooks-js" */),
  "component---src-pages-news-calgaryherald-js": () => import("./../src/pages/news/calgaryherald.js" /* webpackChunkName: "component---src-pages-news-calgaryherald-js" */),
  "component---src-pages-news-chicagotribune-js": () => import("./../src/pages/news/chicagotribune.js" /* webpackChunkName: "component---src-pages-news-chicagotribune-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-seminars-js": () => import("./../src/pages/seminars.js" /* webpackChunkName: "component---src-pages-seminars-js" */),
  "component---src-pages-seminars-parents-js": () => import("./../src/pages/seminars/parents.js" /* webpackChunkName: "component---src-pages-seminars-parents-js" */),
  "component---src-pages-seminars-teens-js": () => import("./../src/pages/seminars/teens.js" /* webpackChunkName: "component---src-pages-seminars-teens-js" */),
  "component---src-pages-seminars-therapists-and-teachers-js": () => import("./../src/pages/seminars/therapistsAndTeachers.js" /* webpackChunkName: "component---src-pages-seminars-therapists-and-teachers-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

